import React from 'react';
import './App.css';
import './style.css';
import './components/login/style.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/navbar';
import Home from './components/Home';
import oaRanking from './components/OverallRanking';
import persRanking from './components/PersonalRanking';
import imprint from './components/Imprint'
import Login from './components/login/Login';
import Settings from './components/Settings';
import PasswordReset from './components/login/PasswordReset';
import PasswordResetForm from './components/login/PasswordResetForm';
import { AvatarProvider } from './components/AvatarContext';
import VerifyEmail from './components/login/VerifyEmail';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <div className="App">
        <AvatarProvider>
          <Navbar />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/oaRanking" component={oaRanking} />
            <Route path="/persRanking" component={persRanking} />
            <Route path="/kontakt" component={imprint} />
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/settings" component={Settings} />
            <Route path="/reset-password/:token" component={PasswordResetForm} /> {/* has to come before the basic url to be able to check for a token */}
            <Route path="/reset-password" component={PasswordReset} />
            <Route path="/confirm-email/:token" component={VerifyEmail} /> {/* TODO change url */}
          </Switch>
        </AvatarProvider>
      </div>
    </Router>
  );
}

export default App;