import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { checkPassword, fetchData } from "./LoginUtils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setnewPw } from "../../backend/data";

function PasswordResetForm() {
    const { token } = useParams();
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const isValidToken = async (token) => {
        const response = await fetchData(`https://rate-my-meal.com/api/check-token/${token}`);
        return response && response.message === "true";
    }

    useEffect(() => {
        const validateToken = async () => {
            const valid = await isValidToken(token);
            setIsValid(valid);
        };
        validateToken();
    }, [token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!checkPassword(password, confirmPassword)) {
            return;
        }
        setnewPw(password, token);
        alert("Password reset successful");
        history.push("/login");
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
            className="App App-header blend-into-image">
            <div
                style={{
                    height: "50vh", margin: '0 10px'
                }}>
                {isValid ? (
                    <form onSubmit={handleSubmit}>
                        <h1 style={{ marginBottom: "calc(8% + 10px)" }} className="">
                            Set New Password
                        </h1>
                        <div className="password-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Enter new password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="toggle-icon sign" onClick={toggleShowPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </span>
                        </div>
                        <div className="password-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                name="confirmPassword"
                                placeholder="Confirm new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <span className="toggle-icon sign" onClick={toggleShowPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </span>
                        </div>
                        <button style={{ marginTop: "calc(4% + 7px)" }} type="submit">Reset Password</button>
                    </form>) : (
                    <form style={{ height: "20svh" }}>
                        <p>Invalid or Expired Reset Token</p>
                    </form>)}
            </div>
        </div>
    );
}

export default PasswordResetForm;