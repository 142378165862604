import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Divider } from '@mui/material';
import { AvatarContext } from './AvatarContext';
import { deleteRanking, deleteUser, resetPwAutoToken } from '../backend/data';
import CustomDialog from './CustomDialog';

function Settings() {
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const [openDeleteRanking, setOpenDeleteRanking] = useState(false);
    const { avatarState, setAvatarState } = useContext(AvatarContext);
    const history = useHistory();

    // reset password
    const handleResetPassword = async (event) => {
        event.preventDefault();
        const token = await resetPwAutoToken(localStorage.getItem('userEmail'));
        console.log("Token: ", token);
        history.push(`/reset-password/${token}`);
    };

    // delete local ranking
    const handleDeleteRanking = (event) => {
        event.preventDefault();
        deleteRanking(avatarState.userEmail);
        setOpenDeleteRanking(false);
        alert('Local ranking deleted.');
    };

    const confirmDeleteRanking = (event) => {
        event.preventDefault();
        setOpenDeleteRanking(true);
    };

    const cancelDeleteRanking = () => {
        setOpenDeleteRanking(false);
    };

    // delete account
    const confirmDeleteAccount = (event) => {
        event.preventDefault();
        setOpenDeleteAccount(true);
    };

    const cancelDeleteAccount = () => {
        setOpenDeleteAccount(false);
    };

    const handleDeleteAccount = (event) => {
        event.preventDefault();
        deleteUser(avatarState.userEmail)
            .then(() => {
                setAvatarState({ loggedIn: false, userEmail: '', avatarColor: 'grey' });
                history.push('');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="App App-header blend-into-image" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'start' }}>
            <h1 className="gradient-text page-header">Einstellungen</h1>
            <form id='settings' style={{ width: 'calc(80% + 20px)', padding: 'calc(2% + 5px)' }}>
                <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center'
                }}>
                    <Avatar sx={{ width: 'calc(13vw + 40px)', height: 'calc(13vw + 40px)', bgcolor: avatarState.avatarColor, fontSize: 'calc(7vw + 15px)' }}>{avatarState.userEmail.charAt(0).toUpperCase()}</Avatar>
                </div>
                <div style={{ padding: 'calc(2% + 4px)', display: 'flex', flexDirection: 'column', alignItems: 'start', width: 'calc(65% + 80px)', fontFamily: 'Neue Regrade Bold' }}>
                    <p>Email: {localStorage.getItem('userEmail')}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'space-between', width: '100%' }}>
                        <p>Password: </p>
                        <input type="password" placeholder='********' disabled={true} style={{ width: '60%', margin: '10px', padding: '10px' }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%' }}>
                        <button onClick={handleResetPassword} className='settings-button' style={{ margin: '0 0 10px 0', backgroundColor: '#282c34', color: 'wheat', }}>Change Password</button>
                    </div>
                    <Divider sx={{ width: '100%', margin: '5px 0', borderWidth: '1px' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <button onClick={confirmDeleteAccount} className='settings-button'>
                            Delete Account
                        </button>
                        <button onClick={confirmDeleteRanking} className='settings-button'>
                            Clear Local Ranking
                        </button>
                    </div>
                </div>
            </form>
            <CustomDialog
                open={openDeleteAccount}
                onClose={cancelDeleteAccount}
                title='Are you sure you want to delete your account?'
                contentText='This action cannot be undone. You would have to create a new account.'
                onConfirm={handleDeleteAccount}
            />
            <CustomDialog
                open={openDeleteRanking}
                onClose={cancelDeleteRanking}
                title='Are you sure you want to delete your local ranking?'
                contentText='This action cannot be undone.'
                onConfirm={handleDeleteRanking}
            />
        </div >
    );
}

export default Settings;